import { ArrowRight } from '@hungryroot/icons';
import { STARTING_ROUTE } from '@hungryroot/quiz';
import { Heading, HeadingVariant } from '@hungryroot/ramen';

import styles from './TakeTheQuizLink.module.css';

export function TakeQuizLink({ className }: { className?: string }) {
  return (
    <Heading className={className} variant={HeadingVariant.H6}>
      <a
        className={styles.link}
        href={STARTING_ROUTE}
        onClick={() => {
          // Clear local storage because the presumption is if a user clicks this button from the login screen
          // they want to start over
          localStorage.clear();
        }}
      >
        Take the quiz <ArrowRight />
      </a>
    </Heading>
  );
}
